import React, { useRef, useState, useEffect } from "react"
import Isotope from "isotope-layout"
import { Swiper, SwiperSlide } from "swiper/react"
import { EffectFade, Navigation } from "swiper"

import Section from "../../components/Section/Section"
import GalleryFilter from "../../components/GalleryFilter/GalleryFilter"
import GalleryCard from "../../components/GalleryCard/GalleryCard"
import ArrowRight from "../../images/dark-arrow-right.svg"
// import ChriateImage from '../../components/ChriateImage';

import "swiper/css"
import "swiper/css/navigation"
import "swiper/css/effect-fade"
import "./GalleryGrid.scss"

// TODO: Figure out how to set the image width to match the object-fit contain width of the image.

const GalleryGrid = ({ moduleId, gallery, hqGallery, lqGallery }) => {
  const swiperRef = useRef(null)
  const scrollTopRef = useRef(null)
  const [filterInit, setFilterInit] = useState(false)
  const [isPopupOpen, setIsPopupOpen] = useState(false)
  const [filter, setFilter] = useState("spaces") // Changes via click AND mouse enter/leave;
  const [activeFilter, setActiveFilter] = useState("spaces") // The filter that is selected/clicked on;
  const cardsDefault = lqGallery
    ? lqGallery.map(obj => {
        const { canvasFilter, ...rest } = obj
        const cardFilter = [canvasFilter?.type ? canvasFilter.type : "none"]
        return { ...rest, cardFilter }
      })
    : []

  const hqCardsDefault = hqGallery
    ? hqGallery.map(obj => {
        const { canvasFilter, ...rest } = obj
        const cardFilter = [canvasFilter?.type ? canvasFilter.type : "none"]
        return { ...rest, cardFilter }
      })
    : []

  const [selectedImages, setSelectedImages] = useState(hqCardsDefault)
  const isotope = useRef(null)
  const isBrowser = typeof window !== "undefined"

  useEffect(() => {
    if (isBrowser) {
      isotope.current = new Isotope(".gallery-grid__gallery", {
        itemSelector: ".gallery-card",
        percentPosition: true,

        masonry: {
          columnWidth: ".grid-sizer",
        },
      })

      setTimeout(() => {
        isotope.current.layout()
        window.isotope = isotope.current

        if (window.innerWidth >= 1024) {
          window.scroll.update()
        }

        window.dispatchEvent(new Event("resize"))
      }, 200)
    }

    return () => {
      isotope.current?.destroy()
    }
  }, [isBrowser])

  useEffect(() => {
    let selectedFilter = activeFilter === "spaces" ? "*" : `.${activeFilter}`
    setSelectedImages(
      hqCardsDefault.filter(
        obj =>
          obj.cardFilter.includes(activeFilter) || activeFilter === "spaces"
      )
    )

    isotope.current.arrange({ filter: selectedFilter })

    setTimeout(() => {
      isotope.current.layout()

      if (window.innerWidth >= 1024) {
        window.scroll.update()
      }

      window.dispatchEvent(new Event("resize"))
    }, 200)
  }, [activeFilter])

  useEffect(() => {
    if (isBrowser) {
      scrollTopRef.current.addEventListener("click", () => {
        if (window.innerWidth >= 1024) {
          window.scroll.scrollTo(0)
        } else {
          window.scrollTo({ top: 0, behavior: "smooth" })
        }
      })

      if (window.innerWidth >= 1024) {
        window.scroll.on("scroll", scrollPos => handleScrolling(scrollPos))
      } else {
        window.addEventListener("scroll", () => handleScrolling(window.scrollY))
      }
    }
    return () => {
      window.removeEventListener("scroll", handleScrolling)
    }
  }, [scrollTopRef])

  const handleFilterChange = f => setFilter(f)

  const handlePopupClick = e => {
    const { target } = e

    if (
      target.classList.contains("gallery-popup") ||
      target.classList.contains("gallery-popup__close")
    ) {
      document.body.classList.remove("show-gallery-popup")
      setIsPopupOpen(false)
    }
  }

  const handleCardClick = id => {
    const selectedImage = selectedImages.find(obj => obj.id === id)
    const filteredImages = selectedImages.filter(obj => obj.id !== id)
    const newSelectedImages = [selectedImage, ...filteredImages]
    // setSelectedImages(newSelectedImages);

    //TODO: figure out how to transition to the selected slide cleanly if the popup element has a conditional that mounts/unmounts on state click.
    swiperRef.current?.slideTo(0, 0, setSelectedImages(newSelectedImages))
  }

  const handleScrolling = scrollPos => {
    if (scrollPos > 1000) {
      scrollTopRef?.current?.classList.add("show")
    } else {
      scrollTopRef?.current?.classList.remove("show")
    }
  }

  return (
    <>
      <Section classes="gallery-grid">
        <div className="container-full">
          <div className="row">
            <div className="column w-100">
              <h4>
                We bring beautiful
                <GalleryFilter
                  handleFilterChange={handleFilterChange}
                  activeFilter={activeFilter}
                  setActiveFilter={setActiveFilter}
                  setFilterInit={setFilterInit}
                />
                to life.
              </h4>
            </div>
          </div>

          <div className="row">
            <div className="column w-100">
              <div className={`gallery-grid__gallery filter--${filter}`}>
                <div className="grid-sizer"></div>
                {cardsDefault.map((obj, i) => {
                  const { localFile, cardFilter, mediaType, mediaItemUrl } = obj
                  return mediaType === "file" ? (
                    <GalleryCard
                      key={obj.id}
                      id={obj.id}
                      video={mediaItemUrl}
                      filterInit={filterInit}
                      classes={cardFilter[0]}
                      handleCardClick={handleCardClick}
                      setIsPopupOpen={setIsPopupOpen}
                      isotope={isotope}
                      delay={i * 200}
                    />
                  ) : (
                    <GalleryCard
                      key={obj.id}
                      id={obj.id}
                      image={{ localFile: localFile }}
                      filterInit={filterInit}
                      classes={cardFilter[0]}
                      handleCardClick={handleCardClick}
                      setIsPopupOpen={setIsPopupOpen}
                      delay={i * 200}
                    />
                  )
                })}
              </div>
            </div>
          </div>
        </div>
      </Section>

      <div className="gallery-popup" onClick={e => handlePopupClick(e)}>
        <div
          className="gallery-popup__close"
          onClick={e => handlePopupClick(e)}
        >
          <span></span>
          <span></span>
        </div>
        <Swiper
          onSwiper={swiper => {
            swiperRef.current = swiper
          }}
          slidesPerView={1}
          effect="fade"
          navigation={true}
          modules={[EffectFade, Navigation]}
        >
          {selectedImages.length
            ? selectedImages.map(obj => {
                const {
                  sourceUrl,
                  localFile,
                  caption,
                  mediaType,
                  mediaItemUrl,
                  video,
                } = obj
                let strippedCaption = caption
                if (caption) {
                  strippedCaption = caption.replace(/<[^>]+>/g, "")
                }

                return sourceUrl ? (
                  <SwiperSlide key={obj.id}>
                    <div className="img-wrap">
                      {/* <ChriateImage image={{ localFile: localFile }} objectFit='contain' /> */}
                      {/* <img src={localFile.childImageSharp.fluid.originalImg} srcSet={localFile.childImageSharp.fluid.srcSet} sizes={localFile.childImageSharp.fluid.sizes} alt={strippedCaption} /> */}
                      <img src={sourceUrl} alt={strippedCaption} lazy="true" />
                      {caption ? (
                        <div
                          className="caption"
                          dangerouslySetInnerHTML={{ __html: caption }}
                        />
                      ) : (
                        ""
                      )}
                    </div>
                  </SwiperSlide>
                ) : mediaType === "file" ? (
                  <SwiperSlide key={obj.id}>
                    <div className="video-wrap">
                      <video autoPlay muted loop playsInline>
                        <source src={mediaItemUrl} type="video/mp4" />
                      </video>
                      {caption ? (
                        <div
                          className="caption"
                          dangerouslySetInnerHTML={{ __html: caption }}
                        />
                      ) : (
                        ""
                      )}
                    </div>
                  </SwiperSlide>
                ) : (
                  ""
                )
              })
            : ""}
        </Swiper>
      </div>

      <div ref={scrollTopRef} className="canvas-scroll-up">
        <span>Top</span>
        <ArrowRight />
      </div>
    </>
  )
}

export default GalleryGrid

