/**
 *
 * This is the Modules WP page template with the Flexible fields
 *
 */

import React from "react"
import Seo from "../components/seo"
import { graphql } from "gatsby"
import ASScroll from "../components/ASScroll/ASScroll"
import GalleryGrid from "../modules/GalleryGrid/GalleryGrid"

const CanvasPage = ({ data: { wpPage, wpMenu, wp, allWpGfForm } }) => {
  const {
    template: {
      canvasTemplate: { gallery, lqGallery, hqGallery },
    },
    slug,
    uri,
    ancestors,
    seo,
  } = wpPage

  return (
    <>
      <ASScroll />
      <Seo seo={seo} />
      <main
        className={`page page--canvas slug-${slug}`}
        asscroll-container="true"
      >
        <GalleryGrid
          gallery={gallery}
          lqGallery={lqGallery}
          hqGallery={hqGallery}
        />
      </main>
    </>
  )
}

export const query = graphql`
  query ($id: String!) {
    wpPage(id: { eq: $id }) {
      title
      slug
      uri
      seo {
        canonical
        cornerstone
        metaKeywords
        metaDesc
        metaRobotsNoindex
        title
      }
      ancestors {
        nodes {
          ... on WpPage {
            title
            uri
            slug
          }
        }
      }
      template {
        ... on WpCanvasTemplate {
          templateName
          canvasTemplate {
            lqGallery: gallery {
              id
              canvasFilter {
                type
              }
              mediaItemUrl
              mediaType
              caption
              localFile {
                childImageSharp {
                  fluid {
                    src
                    originalImg
                    srcSet
                    sizes
                  }
                  gatsbyImageData(
                    transformOptions: { fit: CONTAIN }
                    quality: 45
                    width: 1536
                  )
                }
              }
              sourceUrl
            }
            hqGallery: gallery {
              id
              canvasFilter {
                type
              }
              mediaItemUrl
              mediaType
              caption
              localFile {
                childImageSharp {
                  fluid {
                    src
                    originalImg
                    srcSet
                    sizes
                  }
                  gatsbyImageData(
                    transformOptions: { fit: CONTAIN }
                    quality: 100
                    width: 2048
                  )
                }
              }
              sourceUrl
            }
          }
        }
      }
    }
  }
`

export default CanvasPage
