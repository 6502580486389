import React, { useRef, useEffect, useState } from 'react';
import './GalleryFilter.scss';

const filters = [
  { label: 'spaces', value: 'spaces' },
  { label: 'interiors', value: 'interiors' },
  { label: 'exteriors', value: 'exteriors' }
];

const GalleryFilter = ({ handleFilterChange, activeFilter, setActiveFilter, setFilterInit }) => {
  const [isActive, setIsActive] = useState(false);
  const [ulWidth, setUlWidth] = useState(0);
  const spanRef = useRef(null);
  const filterRef = useRef(null);

  const handleClick = (value) => {
    setActiveFilter(value);
    handleFilterChange(value);
    setIsActive(false);
  };

  const handleMouseEnter = (value) => {
    handleFilterChange(value);
    setFilterInit(true);
  }

  const handleMouseLeave = () => {
    handleFilterChange(activeFilter);
  }

  const handleToggle = () => {
    setIsActive(!isActive);
  };

  const handleOutsideClick = (e) => {
    if (isActive && filterRef.current && !filterRef.current.contains(e.target)) {
      setIsActive(false);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setUlWidth(filterRef.current?.offsetWidth);
    };

    document.addEventListener('click', handleOutsideClick);

    if (filterRef.current) {
      window.addEventListener('resize', handleResize());
    }

    return () => {
      document.removeEventListener('click', handleOutsideClick);
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (isActive && filterRef.current) {
      setUlWidth(filterRef.current.offsetWidth);
    } else {
      setUlWidth(0);
    }
  }, [isActive]);

  const filterItems = filters.filter(f => f.value !== activeFilter);
  const selectedFilterItem = filters.find(f => f.value === activeFilter);

  return (
    <div className="gallery-filters">
      <span ref={spanRef} style={{ 'minWidth': isActive ? ulWidth : '0px' }} className={isActive ? 'active' : ''} onClick={handleToggle}>{selectedFilterItem.label}</span>
      <ul className={`${isActive ? 'active' : ''}`} ref={filterRef}>
        <li className="gallery-filters__filter" onClick={() => handleClick(selectedFilterItem.value)}>
          {selectedFilterItem.label}
        </li>

        {filterItems.length ? filterItems.map(f => (
          <li key={f.value} className="gallery-filters__filter" onClick={() => handleClick(f.value)} onMouseEnter={() => handleMouseEnter(f.value)} onMouseLeave={handleMouseLeave}>
            {f.label}
          </li>
        )) : ''}
      </ul>
    </div>
  );
};

export default GalleryFilter;