import React, { useRef, useEffect } from "react"
import ChriateImage from "../ChriateImage"
import "./GalleryCard.scss"

const Video = ({ video, isotope }) => {
  const videoRef = useRef(null)

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.addEventListener("loadeddata", () => {
        isotope.current.layout()
        // if(videoRef.current.readyState >= 3) {
        //   isotope.current.layout();
        // }
      })
    }
  }, [videoRef])

  return (
    <video ref={videoRef} autoPlay muted loop playsInline preload="metadata">
      <source src={video} type="video/mp4" />
    </video>
  )
}

const GalleryCard = ({
  id,
  image,
  classes,
  handleCardClick,
  setIsPopupOpen,
  video,
  isotope,
}) => {
  const handleClick = () => {
    setIsPopupOpen(true)
    document.body.classList.add("show-gallery-popup")
    handleCardClick(id)
  }

  return (
    <div
      className={`gallery-card gallery-grid ${classes}`}
      onClick={handleClick}
    >
      {image ? (
        <ChriateImage image={image} width={1536} />
      ) : video ? (
        <Video video={video} isotope={isotope} />
      ) : (
        ""
      )}
    </div>
  )
}

export default GalleryCard

